document.addEventListener('turbolinks:load', () => {
    console.log('Completions JavaScript is loaded and running.');

    const jobSelect = document.getElementById('job-select');

    if (jobSelect) {

        const completionLabel = document.getElementById('completion-document-label');
        const completionSentLabel = document.getElementById('completion-sent-document-label');
        const completionDateEndInput = document.querySelector('input[name="completion[completion_date_end_formatted]"]');
        const completionDateEndTd = completionDateEndInput ? completionDateEndInput.closest('td') : null;
        const completionDateStartLabel = document.getElementById('completion-date-start-label'); // Label for completion_date_start
        const festpreisImage = document.getElementById('job-type-image-festpreis');
        const stundenImage = document.getElementById('job-type-image-stunden');
        const noteImage = document.getElementById('job-type-image-note');

        const jobsData = JSON.parse(jobSelect.dataset.jobs || '[]');

        // Function to update label text based on job type
        const updateLabel = () => {
            const selectedJobId = parseInt(jobSelect.value, 10);
            const selectedJob = jobsData.find(job => job.id === selectedJobId);

            if (selectedJob) {
                console.log('Selcted Job: ', selectedJob)

                // Update the label text for completionSentLabel
                if (completionSentLabel) {
                    if (selectedJob['job_type'] === 1) {
                        completionSentLabel.textContent = 'Tätigkeitsnachweis versendet';
                    } else {
                        completionSentLabel.textContent = 'Abnahmebestätigung versendet';
                    }
                }

                // Update the label text for completionLabel
                if (completionLabel) {
                    if (selectedJob['job_type'] === 1) {
                        completionLabel.textContent = 'Tätigkeitsnachweis signiert';
                    } else {
                        completionLabel.textContent = 'Abnahme signiert';
                    }
                }

                // Update the label text for completionDateStartLabel
                if (completionDateStartLabel) {
                    completionDateStartLabel.textContent = selectedJob['job_type'] === 1 ? 'Leistungszeitraum Start' : 'Leistungsdatum';
                }

                // Show/hide only the <td> containing completion_date_end field
                if (completionDateEndTd) {
                    if (selectedJob['job_type'] === 1) {
                        completionDateEndTd.style.display = ''; // Show the <td>
                    } else {
                        completionDateEndTd.style.display = 'none'; // Hide the <td>
                    }
                }

                // Show/hide the <td> containing completion_date_end field
                if (completionDateEndTd) {
                    completionDateEndTd.style.display = selectedJob['job_type'] === 1 ? '' : 'none'; // Show for `1`, hide for `0`
                }

                // Hide all images initially
                festpreisImage.style.display = 'none';
                stundenImage.style.display = 'none';
                noteImage.style.display = 'none';

                // Show the correct job type image
                if (selectedJob['job_type'] === 1) {
                    stundenImage.style.display = '';  // Show "Stunden" image
                } else {
                    festpreisImage.style.display = '';  // Show "Festpreis" image
                }

                // Show the note image if note is present
                if (selectedJob['note']) {
                    noteImage.style.display = '';  // Show note image
                    noteImage.title = selectedJob['note'];  // Set note title dynamically
                }
            }
        };

        // Update label when the form loads
        updateLabel();

        // Update label when the job select value changes
        jobSelect.addEventListener('change', updateLabel);
    }
});
