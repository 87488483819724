// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()


require("bootstrap")
require("flatpickr")
import flatpickr from "flatpickr";

const German = require("flatpickr/dist/l10n/de.js").default.de;

document.addEventListener("turbolinks:load", () => {
    $('[data-tooltip-display="true"]').tooltip(),

    flatpickr("[class='flatpickr']",
        {
            dateFormat: "d.m.Y",
            weekNumbers: true,
            locale: German
        })
})

// stylesheets
require("../stylesheets/application.scss")

import './completions';